import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { formatCents } from '../../lib/helpers'

import { StripeInvoiceItem } from '../../interfaces/StripeInvoiceItem'
import { Table, Header, HeaderCell, Cell, TextCell, NarrowCell } from '../common/Table'

// @ts-ignore
import deleteIcon from '../lib/images/delete.png'

const defaultColumn: any = {
  TextCell,
}

interface Props {
  data: StripeInvoiceItem[]
  total: string
}

export default function InvoiceItemsTable(props: Props) {
  const { data } = props

  const columns = useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Unit Price',
        accessor: (s: StripeInvoiceItem) => s.unit_amount_text || (s.unit_amount === 0 ? 'FREE' : formatCents(s.unit_amount)),
      },
      {
        Header: 'Amount (USD)',
        accessor: (s: StripeInvoiceItem) =>
          s.amount ? formatCents(s.amount) : s.unit_amount === 0 ? 'FREE' : formatCents(s.quantity * s.unit_amount),
      },
    ],
    []
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
    defaultColumn,
  })

  return (
    <div className="border rounded p-4 mb-4">
      <h5 className="bold">Overview</h5>

      <Table className="w-100" cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, idx: number) => (
                <HeaderCell key={idx} {...column.getHeaderProps()}>
                  <Header>{column.render('Header')}</Header>
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  return (
                    <NarrowCell key={idx} {...cell.getCellProps()}>
                      {cell.render('TextCell')}
                    </NarrowCell>
                  )
                })}
              </tr>
            )
          })}
          <tr>
            <Cell />
            <Cell />
            <Cell />
            <Cell>
              <p className="mb-0 text-l">
                <span className="bold mr-2">TOTAL</span>${props.total}
              </p>
            </Cell>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
