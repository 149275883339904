import React from 'react'
import { Button } from 'reactstrap'
import { useTable } from 'react-table'

import { Table, Header, HeaderCell, TextCell, NoResults, Row, isColumn, NarrowCell } from '../common/Table'

const defaultColumn: any = {
  TextCell,
}

interface Props {
  data: any[]
  columns: any[]
  handleDownloadAsCsv?: () => void
}

export default function LineItemsTable(props: Props) {
  const { data, columns } = props

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data,
    defaultColumn,
  })

  const colSpan = (data: any) => (isColumn(data, 'Description') ? 2 : 1)

  return (
    <div className="border rounded p-4">
      <h5 className="bold">Consultations</h5>

      <Table cellSpacing={0} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any, idx: number) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <HeaderCell {...column.getHeaderProps()} colSpan={colSpan(column)}>
                  <Header>{column.render('Header')}</Header>
                </HeaderCell>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row: any, idx: number) => {
              prepareRow(row)

              return (
                <Row key={idx} {...row.getRowProps()}>
                  {row.cells.map((cell: any, idx2: number) => {
                    return (
                      <NarrowCell key={idx2} {...cell.getCellProps()} colSpan={colSpan(cell.column)}>
                        {cell.render('TextCell')}
                      </NarrowCell>
                    )
                  })}
                </Row>
              )
            })
          ) : (
            <NoResults columns={columns?.length} message="No line items in current billing period." />
          )}
        </tbody>
      </Table>

      {props.handleDownloadAsCsv && (
        <Button color="primary" className="mt-4 d-block" disabled={!data.length} onClick={props.handleDownloadAsCsv}>
          Download
        </Button>
      )}
    </div>
  )
}
