import React from 'react'

interface Props {
  title: string
  content?: number | string
  highlight?: boolean
}

export default function Statistic(props: Props) {
  return (
    <div className="border rounded px-4 pb-3 pt-4 d-flex flex-column justify-content-between">
      <p className="text-l bold mb-2">{props.title}</p>
      <p className={`text-xxl m-0 ${props.highlight ? 'text--success semibold' : ''}`}>{props.content || '-'}</p>
    </div>
  )
}
